import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  // {
  //   name: 'legacyId',
  //   label: i18n('entities.devices.fields.legacyId'),
  //   schema: schemas.integer(
  //     i18n('entities.devices.fields.legacyId'),
  //     {
  //       "required": true
  //     },
  //   ),
  // },
  {
    name: 'name',
    label: i18n('entities.devices.fields.name'),
    schema: schemas.string(
      i18n('entities.devices.fields.name'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.devices.fields.description'),
    schema: schemas.string(
      i18n('entities.devices.fields.description'),
      {},
    ),
  },
  {
    name: 'assetType',
    label: i18n('entities.devices.fields.assetType'),
    schema: schemas.relationToOne(
      i18n('entities.devices.fields.assetType'),
      {},
    ),
  },
  {
    name: 'assetSubtype',
    label: i18n('entities.devices.fields.assetSubtype'),
    schema: schemas.relationToOne(
      i18n('entities.devices.fields.assetSubtype'),
      {},
    ),
  },
  {
    name: 'telemetryKeys',
    label: i18n('entities.devices.fields.telemetryKeys'),
    schema: schemas.relationToMany(
      i18n('entities.devices.fields.telemetryKeys'),
      {},
    ),
  },
  {
    name: 'icon',
    label: i18n('entities.devices.fields.icon'),
    schema: schemas.images(
      i18n('entities.devices.fields.icon'),
      {},
    ),
  },
  {
    name: 'secrets',
    label: i18n('entities.devices.fields.secrets'),
    schema: schemas.string(
      i18n('entities.devices.fields.secrets'),
      {},
    ),
  },
  {
    name: 'config',
    label: i18n('entities.devices.fields.config'),
    schema: schemas.string(
      i18n('entities.devices.fields.config'),
      {},
    ),
  },
  {
    name: 'extra',
    label: i18n('entities.devices.fields.extra'),
    schema: schemas.string(
      i18n('entities.devices.fields.extra'),
      {},
    ),
  },
  {
    name: 'sensors',
    label: i18n('entities.devices.fields.sensors'),
    schema: schemas.relationToMany(
      i18n('entities.devices.fields.sensors'),
      {},
    ),
  },
  {
    name: 'location',
    label: i18n('entities.devices.fields.location'),
    schema: schemas.relationToOne(
      i18n('entities.devices.fields.location'),
      {},
    ),
  },
  {
    name: 'building',
    label: i18n('entities.devices.fields.building'),
    schema: schemas.relationToOne(
      i18n('entities.devices.fields.building'),
      {},
    ),
  },
  {
    name: 'floor',
    label: i18n('entities.devices.fields.floor'),
    schema: schemas.relationToOne(
      i18n('entities.devices.fields.floor'),
      {},
    ),
  },
];