import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import React from 'react';
// import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
// import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import BusinessIcon from '@material-ui/icons/Business';
// import LocationCity from '@material-ui/icons/LocationCity';
// import ApartmentIcon from '@material-ui/icons/Apartment';
// import ViewStreamIcon from '@material-ui/icons/ViewStream';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
// import SensorsIcon from '@mui/icons-material/Sensors';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
// import HubIcon from '@mui/icons-material/Hub';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
// import config from 'src/config';

const permissions = Permissions.values;

export default [
  {
    path: '/',
    exact: true,
    icon: <DashboardIcon />,
    label: i18n('dashboard.menu'),
    permissionRequired: null,
  },

  // config.isPlanEnabled && {
  //   path: '/plan',
  //   permissionRequired: permissions.planRead,
  //   icon: <CreditCardOutlinedIcon />,
  //   label: i18n('plan.menu'),
  // },
  
  {
    path: '/tenant',
    label: i18n('tenant.menu'),
    permissionRequired: permissions.userRead,
    icon: <BusinessIcon />,
  },

  {
    path: '/user',
    label: i18n('user.menu'),
    permissionRequired: permissions.userRead,
    icon: <PersonIcon />,
  },

  // {
  //   path: '/audit-logs',
  //   icon: <HistoryIcon />,
  //   label: i18n('auditLog.menu'),
  //   permissionRequired: permissions.auditLogRead,
  // },

  {
    path: '/settings',
    icon: <SettingsIcon />,
    label: i18n('settings.menu'),
    permissionRequired: permissions.settingsEdit,
  },

  // {
  //   path: '/locations',
  //   permissionRequired: permissions.locationsRead,
  //   icon: <LocationCity />,
  //   label: i18n('entities.locations.menu'),
  // },

  // {
  //   path: '/buildings',
  //   permissionRequired: permissions.buildingsRead,
  //   icon: <ApartmentIcon />,
  //   label: i18n('entities.buildings.menu'),
  // },

  // {
  //   path: '/floors',
  //   permissionRequired: permissions.floorsRead,
  //   icon: <ViewStreamIcon />,
  //   label: i18n('entities.floors.menu'),
  // },

  {
    path: '/assets',
    permissionRequired: permissions.assetsRead,
    icon: <WebAssetIcon />,
    label: i18n('entities.assets.menu'),
  },

  {
    path: '/asset-types',
    permissionRequired: permissions.assetTypesRead,
    icon: <AccountTreeIcon />,
    label: i18n('entities.assetTypes.menu'),
  },

  // {
  //   path: '/asset-sub-types',
  //   permissionRequired: permissions.assetSubTypesRead,
  //   icon: <AccountTreeIcon />,
  //   label: i18n('entities.assetSubTypes.menu'),
  // },

  // {
  //   path: '/telemetry-keys',
  //   permissionRequired: permissions.telemetryKeysRead,
  //   icon: <ChevronRightIcon />,
  //   label: i18n('entities.telemetryKeys.menu'),
  // },

  // {
  //   path: '/sensors',
  //   permissionRequired: permissions.sensorsRead,
  //   icon: <SensorsIcon />,
  //   label: i18n('entities.sensors.menu'),
  // },

  // {
  //   path: '/sensor-profile',
  //   permissionRequired: permissions.sensorProfileRead,
  //   icon: <SensorOccupiedIcon />,
  //   label: i18n('entities.sensorProfile.menu'),
  // },

  // {
  //   path: '/telemetry',
  //   permissionRequired: permissions.telemetryRead,
  //   icon: <ChevronRightIcon />,
  //   label: i18n('entities.telemetry.menu'),
  // },

  // {
  //   path: '/notifications',
  //   permissionRequired: permissions.notificationsRead,
  //   icon: <NotificationsIcon />,
  //   label: i18n('entities.notifications.menu'),
  // },
  // {
  //   path: '/gateways',
  //   permissionRequired: permissions.gatewaysRead,
  //   icon: <HubIcon />,
  //   label: i18n('entities.gateways.menu'),
  // },

  {
    path: '/orders',
    permissionRequired: permissions.ordersRead,
    icon: <AssignmentIcon />,
    label: i18n('entities.orders.menu'),
  },

  {
    path: '/user-permissions',
    permissionRequired: permissions.userPermissionsRead,
    icon: <SettingsAccessibilityIcon />,
    label: i18n('entities.userPermissions.menu'),
  },

  {
    path: '/firmwares',
    permissionRequired: permissions.firmwaresRead,
    icon: <SystemUpdateAltIcon />,
    label: i18n('entities.firmwares.menu'),
  },

  {
    path: '/devices',
    permissionRequired: permissions.devicesRead,
    icon: <DeviceHubIcon />,
    label: i18n('entities.devices.menu'),
  },

].filter(Boolean);
