import list from 'src/modules/devices/list/devicesListReducers';
import form from 'src/modules/devices/form/devicesFormReducers';
import view from 'src/modules/devices/view/devicesViewReducers';
import destroy from 'src/modules/devices/destroy/devicesDestroyReducers';
import importerReducer from 'src/modules/devices/importer/devicesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
